import clsx from 'clsx'
import {ModernMTLogo} from '~/ui/ModernMTLogo'

export function Header({
  className,
  children,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <header
      {...props}
      className={clsx(
        'border-b-1 border-b-neutral-400 flex justify-between gap-4',
        'bg-neutral-25',
        className,
      )}
    >
      <a href="/" aria-label="Go to home page">
        <ModernMTLogo />
      </a>
      {children}
    </header>
  )
}
